import PotionCarousel from "../../Admin-Scenes/BreedingScreenComp/PotionCarousel/PotionCarousel";
import {
  Box,
  Grid,
  Button,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import LogoutComp from "../../Logout/Logout";
import { ToastContainer, toast } from "react-toastify";

import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { setSnackbar } from "../../../redux/Snackbar/SnackbarReducder";
import { setLoading } from "../../../redux/LoaderReducer/LoaderReducer";
import { axiosRequest, axiosRequestNFT } from "../../api/api";
import { setNFT } from "../../../redux/NFTReducer/NFTReducer";

import HeaderAndBreedButton from "../../Admin-Scenes/BreedingScreenComp/HeaderAndBreedbutton/HeaderAndBreedButton";
import BreedingButton from "../../BredingComp/BreedingButton/BreedingButton";
import ChooseMaleNft from "../../Admin-Scenes/BreedingScreenComp/ChooseNft/ChooseMaleNft";
import ChooseFemaleNft from "../../Admin-Scenes/BreedingScreenComp/ChooseNft/ChooseFemaleNft";
import { useLocation, useNavigate } from "react-router-dom";
import { CampaignType } from "../../../types/campaignTypes";
import { Collection } from "../../../types/collectiontype";
import { Base_Url } from "../../api/api";
import { setPotionId } from "../../../redux/PotionReducer/PotionReducer";
import {
  setFemale,
  setMale,
  setUri,
} from "../../../redux/GenderReducer/GenderReducer";

interface collection {
  data2: Collection;
}
interface campaignPropI {
  campaignState: CampaignType;
}

const Single = ({ campaignState }: campaignPropI) => {
  const state: CampaignType = campaignState;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRouteName = location.pathname;
  const getNftURL = currentRouteName.includes("rewardbag")
    ? "api/luxlion/rewardbag/nfts?userAccount="
    : "api/luxlion/nfts?userAccount=";
  const campaignsSelector = useSelector(
    (state: any) => state.campaigns.campaigns
  );

  ////console.log("state---->01", state);
  const backgroundUrl = currentRouteName.includes("rewardbag")
    ? Base_Url + `/rewardbagBackground/${state?.background}`
    : Base_Url + `/${state?.background}`;
  const logoUrl = currentRouteName.includes("rewardbag")
    ? Base_Url + `/rewardbagLogo/${state?.logo}`
    : Base_Url + `/${state?.logo}`;
  const accountNum = localStorage.getItem("accnum");
  ////console.log("account number", accountNum);
  const isScreenLessThan1725px = useMediaQuery("(max-width:1725px)");
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const nfts = useSelector((state: any) => state.nfts?.nftObj);
  ////console.log("aPI ADDRESS", Base_Url);
  ////console.log("The nft we are fetching here", nfts);
  //console.log(nfts, "nfts");
  const potionToken = useSelector((state: any) => state.potion?.potionId);
  const maleToken = useSelector((state: any) => state.gender?.male);
  const femaleToken = useSelector((state: any) => state.gender?.female);

  // const clctnName = useSelector((state:any)=>state.minter.ser)

  const [potionSelectedState, setPotionSelectedState] =
    useState<boolean>(false);

  const [isFemaleNftSelected, setIsFemaleNftSelected] =
    useState<boolean>(false);

  const [isMaleNftSelected, setIsMaleNftSelected] = useState<boolean>(false);

  const firstHitApi2 = useSelector((state: any) => state.loader?.firstHitApi);

  const loadingState = useSelector((state: any) => state.loader?.loading);

  const areMaleNfts = !!nfts?.GroupA?.length;
  //console.log("Male NFTS", areMaleNfts);
  const areFemaleNfts = !!nfts?.GroupB?.length;

  useEffect(() => {
    getNFT(accountNum, state?.campaign_id);
  }, [state?.campaign_id]);

  async function getNFT(data: any, id: number) {
    dispatch(setLoading(true));
    try {
      const response: any = await axiosRequestNFT(
        "post",
        `${getNftURL}${data}`,
        { id: id },
        undefined
      );
      //console.log("data of account ID", data, response);
      dispatch(setNFT(response));
      dispatch(setLoading(false));
    } catch (error: any) {
      dispatch(setLoading(false));
      console.error("what is error", error);
      if (error) {
        toast.error(error);
      } else if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong please try again!! ");
      }
      dispatch(setNFT(undefined));
    }
  }

  useEffect(() => {
    if (potionToken != "") {
      setPotionSelectedState(true);
    } else {
      setPotionSelectedState(false);
    }
    //console.log(isFemaleNftSelected, "isFemaleNftSelected", isMaleNftSelected);

    if (maleToken === "" || maleToken === undefined) {
      setIsMaleNftSelected(false);
    } else {
      setIsMaleNftSelected(true);
    }

    if (femaleToken === "" || femaleToken === undefined) {
      setIsFemaleNftSelected(false);
    } else {
      setIsFemaleNftSelected(true);
    }
  }, [potionToken, maleToken, femaleToken]);

  const handleOpenModal = () => {
    //console.log(isFemaleNftSelected, "yeahehhhaeaeh", isMaleNftSelected);
    //console.log(femaleToken, "token");

    if (potionSelectedState === false) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Please select potion NFT `,
        })
      );
      return;
    }

    if (isMaleNftSelected === false) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Please select a Lion `,
        })
      );
      return;
    }

    if (isFemaleNftSelected === false) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage: `Please select a Lioness `,
        })
      );
      return;
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";

    dispatch(setMale(""));
    dispatch(setFemale(""));
    dispatch(setPotionId(""));
  }, []);

  return (
    <>
      {state?.campaignType === "3" ? (
        <div style={{ position: "relative" }}>
          <img
            src={backgroundUrl}
            alt="img"
            style={{ width: "100%", height: "100vh" }}
          />
          <div
            style={{
              position: "absolute",
              height: "100vh",
              overflowY: "auto",
              top: 0,
              width: "100%",
            }}
          >
            {loadingState ? (
              <Box
                height={"100vh"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CircularProgress size={70} sx={{ color: "white" }} />
              </Box>
            ) : (
              <Grid container style={{ zIndex: 0 }}>
                <Grid
                  item
                  sm={3}
                  sx={{ paddingTop: 4, display: "flex" }}
                  justifyContent="center"
                >
                  <Box sx={{ maxWidth: 150, position: "relative" }}>
                    <img
                      src={logoUrl}
                      alt="img"
                      width="100%"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (currentRouteName.includes("rewardbag"))
                          navigate("/campaigns/rewardbag");
                        else navigate("/campaigns");
                      }}
                      onMouseEnter={() => {
                        // Change the cursor to pointer when hovering
                        const imgElement = document.querySelector("img");
                        if (imgElement) {
                          imgElement.style.cursor = "pointer";
                        }
                      }}
                      onMouseLeave={() => {
                        // Restore the default cursor when not hovering
                        const imgElement = document.querySelector("img");
                        if (imgElement) {
                          imgElement.style.cursor = "auto"; // Restore default cursor
                        }
                      }}
                    />
                  </Box>
                </Grid>

                <HeaderAndBreedButton
                  campaign={state}
                  isScreenLessThan1725px={isScreenLessThan1725px}
                  handleOpenModal={handleOpenModal}
                />

                <Grid
                  item
                  sm={3}
                  sx={{ paddingTop: 4, display: "flex" }}
                  justifyContent="center"
                >
                  <Box sx={{ textAlign: "right", minHeight: 5 }}>
                    <LogoutComp />
                  </Box>
                </Grid>

                <Box
                  width="100%"
                  display="flex"
                  alignItems="center" // Vertical alignment
                  justifyContent="center" // Horizontal alignment
                  minHeight="50vh" // Optional: to center vertically on the whole screen
                >
                  <Grid container>
                    {isScreenLessThan1725px ? (
                      <>
                        <Grid item sm={3}></Grid>

                        <Grid item sm={6}>
                          <Box
                            sx={{
                              background: "rgba(217, 217, 217, 0.8);",
                              borderRadius: "15px",
                              width: "100%",
                              height: 65,
                              zIndex: 99,
                              position: "relative",
                              // mt: "10%",
                            }}
                          >
                            {nfts?.potionNfts?.length === 0 ? (
                              <Typography
                                sx={{
                                  color: "black",
                                  fontSize: "30px",
                                  paddingLeft: "25%",
                                  paddingTop: "1%",
                                }}
                              >
                                No Potions are Available
                              </Typography>
                            ) : (
                              <PotionCarousel />
                            )}
                          </Box>

                          <Grid item sm={3}></Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item sm={3}></Grid>

                        <Grid item sm={6}>
                          <Box
                            sx={{
                              background: "rgba(217, 217, 217, 0.8);",
                              borderRadius: "15px",
                              width: "100%",

                              zIndex: 99,
                              position: "relative",
                              p: 2,
                              mt: "5%",
                            }}
                          >
                            {nfts?.potionNfts?.length === 0 ? (
                              <Typography
                                sx={{
                                  color: "black",
                                  fontSize: "30px",
                                  paddingLeft: "37%",
                                  paddingTop: "1%",
                                }}
                              >
                                No Potions are Available
                              </Typography>
                            ) : (
                              <PotionCarousel />
                            )}
                          </Box>
                        </Grid>

                        <Grid item sm={3}></Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Grid>
            )}
          </div>
        </div>
      ) : state?.campaignType === "2" ? (
        <div style={{ position: "relative" }}>
          <img
            src={backgroundUrl}
            alt="img"
            style={{ width: "100%", height: "100vh" }}
          />
          <div
            style={{
              position: "absolute",
              height: "100vh",
              overflowY: "auto",
              top: 0,
              width: "100%",
            }}
          >
            {loadingState ? (
              <Box
                height={"100vh"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CircularProgress size={70} sx={{ color: "white" }} />
              </Box>
            ) : (
              <Grid container style={{ zIndex: 0 }}>
                <Grid
                  item
                  sm={3}
                  sx={{ paddingTop: 2, display: "flex" }}
                  justifyContent="center"
                >
                  <Box sx={{ maxWidth: 150, position: "relative" }}>
                    <img
                      src={`${logoUrl}`}
                      alt="img"
                      width="100%"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/campaigns");
                      }}
                      onMouseEnter={() => {
                        // Change the cursor to pointer when hovering
                        const imgElement = document.querySelector("img");
                        if (imgElement) {
                          imgElement.style.cursor = "pointer";
                        }
                      }}
                      onMouseLeave={() => {
                        // Restore the default cursor when not hovering
                        const imgElement = document.querySelector("img");
                        if (imgElement) {
                          imgElement.style.cursor = "auto"; // Restore default cursor
                        }
                      }}
                    />
                  </Box>
                </Grid>
                <HeaderAndBreedButton
                  campaign={state}
                  isScreenLessThan1725px={isScreenLessThan1725px}
                  handleOpenModal={handleOpenModal}
                />

                <Grid
                  item
                  sm={3}
                  sx={{ paddingTop: 4, display: "flex" }}
                  justifyContent="center"
                >
                  <Box sx={{ textAlign: "right", minHeight: 5 }}>
                    <LogoutComp />
                  </Box>
                </Grid>

                <Grid
                  item
                  sm={12}
                  sx={{
                    pt: CAROUSEL_TOP_PADDING,
                    display: "flex",
                    marginTop: "90px",
                  }}
                  justifyContent="center"
                >
                  {loadingState ? (
                    <Box
                      minHeight={"50px"}
                      maxHeight={"500px"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <CircularProgress size={70} sx={{ color: "white" }} />
                    </Box>
                  ) : (
                    <>
                      {nfts?.GroupA?.length === 0 ||
                      nfts?.GroupA == undefined ? (
                        <Typography sx={{ color: "white", fontSize: "30px" }}>
                          NO NFT AVAILABLE
                        </Typography>
                      ) : (
                        <ChooseMaleNft
                          nfts={nfts}
                          areMaleNfts={true}
                          isScreenLessThan1725px={isScreenLessThan1725px}
                        />
                      )}
                    </>
                  )}
                </Grid>

                <Box width="100%">
                  <Grid container>
                    {isScreenLessThan1725px ? (
                      <>
                        <Grid item sm={3}></Grid>

                        <Grid item sm={6}>
                          <Box
                            sx={{
                              background: "rgba(217, 217, 217, 0.8);",
                              borderRadius: "15px",
                              width: "100%",
                              height: 65,
                              zIndex: 99,
                              position: "relative",
                              mt: "1%",
                            }}
                          >
                            {nfts?.potionNfts?.length === 0 ? (
                              <Typography
                                sx={{
                                  color: "black",
                                  fontSize: "30px",
                                  paddingLeft: "25%",
                                }}
                              >
                                No Potions are Available
                              </Typography>
                            ) : (
                              <PotionCarousel />
                            )}
                          </Box>

                          <Grid item sm={3}></Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item sm={3}></Grid>

                        <Grid item sm={6}>
                          <Box
                            sx={{
                              background: "rgba(217, 217, 217, 0.8);",
                              borderRadius: "15px",
                              width: "100%",

                              zIndex: 99,
                              position: "relative",
                              p: 2,
                              mt: "1%",
                            }}
                          >
                            {nfts?.potionNfts?.length === 0 ? (
                              <Typography
                                sx={{
                                  color: "black",
                                  fontSize: "30px",
                                  paddingLeft: "37%",
                                }}
                              >
                                No Potions are Available
                              </Typography>
                            ) : (
                              <PotionCarousel />
                            )}
                          </Box>
                        </Grid>

                        <Grid item sm={3}></Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Grid>
            )}
          </div>
        </div>
      ) : state?.campaignType === "1" ? (
        <div
          style={{
            position: "relative",
          }}
        >
          <img
            src={backgroundUrl}
            alt="img"
            style={{ width: "100%", height: "100vh" }}
          />
          <div
            style={{
              position: "absolute",

              height: "100vh",
              overflowY: "auto",
              top: 0,
              width: "100%",
            }}
          >
            {loadingState ? (
              <Box
                height={"100vh"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CircularProgress size={70} sx={{ color: "white" }} />
              </Box>
            ) : (
              <Grid container style={{ zIndex: 0 }}>
                <Grid
                  item
                  sm={3}
                  sx={{ paddingTop: 4, display: "flex" }}
                  justifyContent="center"
                >
                  <Box sx={{ maxWidth: 150, position: "relative" }}>
                    <img
                      src={`${logoUrl}`}
                      alt="img"
                      width="100%"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (currentRouteName.includes("rewardbag"))
                          navigate("/campaigns/rewardbag");
                        else navigate("/campaigns");
                      }}
                      onMouseEnter={() => {
                        // Change the cursor to pointer when hovering
                        const imgElement = document.querySelector("img");
                        if (imgElement) {
                          imgElement.style.cursor = "pointer";
                        }
                      }}
                      onMouseLeave={() => {
                        // Restore the default cursor when not hovering
                        const imgElement = document.querySelector("img");
                        if (imgElement) {
                          imgElement.style.cursor = "auto"; // Restore default cursor
                        }
                      }}
                    />
                  </Box>
                </Grid>

                <HeaderAndBreedButton
                  campaign={state}
                  isScreenLessThan1725px={isScreenLessThan1725px}
                  handleOpenModal={handleOpenModal}
                />

                <Grid
                  item
                  sm={3}
                  sx={{ paddingTop: 4, display: "flex" }}
                  justifyContent="center"
                >
                  <Box sx={{ textAlign: "right", minHeight: 5 }}>
                    <LogoutComp />
                  </Box>
                </Grid>

                {!currentRouteName.includes("rewardbag") && (
                  <Grid
                    item
                    sm={4}
                    sx={{ px: 2, pt: CAROUSEL_TOP_PADDING, display: "flex" }}
                    justifyContent="center"
                  >
                    {loadingState ? (
                      <Box
                        minHeight={"50px"}
                        maxHeight={"500px"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <CircularProgress size={70} sx={{ color: "white" }} />
                      </Box>
                    ) : (
                      <>
                        {nfts?.GroupA?.length === 0 || nfts?.GroupA == null ? (
                          <Typography
                            sx={{
                              background: "gray",
                              color: "white",
                              fontSize: "30px",
                            }}
                          >
                            NO NFT AVAILABLE
                          </Typography>
                        ) : (
                          <ChooseMaleNft
                            nfts={nfts}
                            areMaleNfts={true}
                            isScreenLessThan1725px={isScreenLessThan1725px}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                )}
                <Grid item sm={4} sx={{ pt: 2, ml: 0, textAlign: "center" }}>
                  <Box width="100%"></Box>
                </Grid>
                {!currentRouteName.includes("rewardbag") && (
                  <Grid
                    item
                    sm={4}
                    sx={{ pt: CAROUSEL_TOP_PADDING, display: "flex" }}
                    justifyContent="center"
                  >
                    {loadingState ? (
                      <Box
                        minHeight={"50px"}
                        maxHeight={"500px"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <CircularProgress size={70} sx={{ color: "white" }} />
                      </Box>
                    ) : (
                      <>
                        {nfts?.GroupB?.length === 0 || nfts?.GroupB == null ? (
                          <Typography
                            sx={{
                              background: "gray",
                              color: "white",
                              fontSize: "30px",
                            }}
                          >
                            NO NFT AVAILABLE
                          </Typography>
                        ) : (
                          <ChooseFemaleNft
                            isScreenLessThan1725px={isScreenLessThan1725px}
                            nfts={nfts}
                            areFemaleNfts={true}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                )}
                <Box width="100%">
                  <Grid container>
                    {isScreenLessThan1725px ? (
                      <>
                        <Grid item sm={3}></Grid>

                        <Grid item sm={6}>
                          <Box
                            sx={{
                              background: "rgba(217, 217, 217, 0.8);",
                              borderRadius: "15px",
                              width: "100%",

                              zIndex: 99,
                              position: "relative",
                              mt: "5%",
                            }}
                          >
                            {nfts?.potionNfts?.length === 0 ? (
                              <Typography
                                sx={{
                                  color: "black",
                                  fontSize: "30px",
                                  paddingLeft: "25%",
                                }}
                              >
                                No Potions are Available
                              </Typography>
                            ) : (
                              <PotionCarousel />
                            )}
                          </Box>

                          <Grid item sm={3}></Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item sm={3}></Grid>

                        <Grid item sm={6}>
                          <Box
                            sx={{
                              background: "rgba(217, 217, 217, 0.8);",
                              borderRadius: "15px",
                              width: "100%",

                              zIndex: 99,
                              position: "relative",
                              p: 2,
                              mt: "5%",
                            }}
                          >
                            {nfts?.potionNfts?.length === 0 ? (
                              <Typography
                                sx={{
                                  color: "black",
                                  fontSize: "30px",
                                  paddingLeft: "37%",
                                }}
                              >
                                No Potions are Available
                              </Typography>
                            ) : (
                              <PotionCarousel />
                            )}
                          </Box>
                        </Grid>

                        <Grid item sm={3}></Grid>
                      </>
                    )}
                  </Grid>
                </Box>
              </Grid>
            )}
          </div>
        </div>
      ) : (
        <Typography>Admin did not selected the Campaign Type</Typography>
      )}
      <ToastContainer />
    </>
    // <div style={{ position: "relative" }}>
    //   <video width="100%" src={`${PotionBurn}`} autoPlay muted></video>
    // </div>
  );
};
export default Single;

const CAROUSEL_TOP_PADDING = 0;

const modalStyles = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 400,
  bgcolor: "rgba(109, 220, 254,1)",
  boxShadow: 24,
  p: 4,
};

let isMaleNftSelected = false;
let isFemaleNftSelected = false;
