import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { width } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { CampaignType } from "../../../../types/campaignTypes";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  axiosRequestLocal,
  axiosRequestNFT,
  Base_Url,
  axiosRequestAuth,
} from "../../../api/api";
import react, { useState } from "react";
import PotionBurn from "../../../../assets/RewardAssets/Potion-Burn.mp4";
import RewardNFT from "../../../../assets/RewardAssets/Reward-Nft.mp4";
import RewardNftList from "../../Campaigns/single/RewardNftList";
import "./video.css";

interface headerAndBreedButtonPropsI {
  handleOpenModal: () => void;
  isScreenLessThan1725px: boolean;
  campaign: CampaignType;
}
interface Attribute {
  trait_type: string;
  description: string;
  value: string;
}

interface Content {
  attributes: Attribute[];
  collection: {
    name: string;
    family: string;
  };
  name: string;
  NFTokenTaxon: number;
  description: string;
  schema: string;
  image?: string;
  video?: string;
  nftType: string;
}

interface NFT {
  Flags: number;
  Issuer: string;
  NFTokenID: string;
  NFTokenTaxon: number;
  URI: string;
  nft_serial: number;
  content: Content;
  isClaim: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: "65%",
  bgcolor: "rgba(109, 220, 254,1)",
  p: 4,
};

function HeaderAndBreedButton({
  handleOpenModal,
  campaign,
  isScreenLessThan1725px,
}: headerAndBreedButtonPropsI) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRouteName = location.pathname;
  const potion = useSelector((state: any) => state.potion);
  const potionId = useSelector((state: any) => state.potion?.potionId);
  const maleToken = useSelector((state: any) => state.gender?.male);
  const femaleToken = useSelector((state: any) => state.gender?.female);
  const [firstVideo, setFirstVideo] = useState(false);
  const [secondVideo, setSecondVideo] = useState(false);
  const [nftList, setNftList] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);

  ////console.log("Potion ID on HeaderAnd Breed Comp", potionId);
  ////console.log(potion, "potion");
  const handleButtonClick = async () => {
    if (potion.potionId || potionId) {
      if (campaign) {
        const { campaignType, campaign_id } = campaign;
        if (currentRouteName.includes("rewardbag")) {
          try {
            setModalOpen(true);

            const response: any = await axiosRequestAuth(
              "post",
              "api/luxlion/rewardbag/potionburn",
              { potion: potion },
              undefined
            );
            // const response: any = await axiosRequestLocal(
            //   "get",
            //   Base_Url + `api/luxlion/rewardbag/potionburn`,
            //   { jwt: token, userAccount: accountAddress, potion: potionId },
            //   {}
            // );
            //console.log(response);
            if (response?.data?.success) {
              toast.success("Potion Burned Successfully.", {
                autoClose: 3000,
              });
              setModalOpen(false);

              if (campaign.videoType === 1) setFirstVideo(true);
              if (campaign.videoType === 2) setSecondVideo(true);
              const response1: any = await axiosRequestAuth(
                "post",
                "api/luxlion/rewardbagnftsgift",
                { id: campaign.campaign_id, potion: potion },
                undefined
              );

              if (response1.status) {
                let data: any[] = response1.data.contents.map((item: NFT) => ({
                  ...item,
                  isClaim: false,
                }));
                setNftList(data);
                setFirstVideo(false);
                setSecondVideo(false);
                toast.success("Request Accepted Successfully.", {
                  autoClose: 3000,
                });
              } else {
                setModalOpen(false);
                setFirstVideo(false);
                setSecondVideo(false);
                toast.error("NFTs not available", {
                  autoClose: 3000,
                });
              }
            } else {
              setModalOpen(false);
              setFirstVideo(false);
              setSecondVideo(false);
              toast.error("Request Failed.", {
                autoClose: 3000,
              });
            }
          } catch (error: any) {
            //console.log("Error fetching data:", error);
            setModalOpen(false);
            setFirstVideo(false);
            setSecondVideo(false);
            toast.error(error?.response?.data?.message || "Request Failed.", {
              autoClose: 3000,
            });
          }
        } else {
          if (campaignType === "1") {
            if (maleToken && femaleToken && potionId) {
              navigate(`/characterBuilding/${campaign_id}`);
            } else if (!maleToken && femaleToken && potionId) {
              toast.error("Please select Group A");
            } else if (!femaleToken && maleToken && potionId) {
              toast.error("Please select Group B");
            } else if (femaleToken && maleToken && !potionId) {
              toast.error("Please select a Potion");
            } else {
              toast.error("Please select all required tokens.");
            }
          } else if (campaignType === "2") {
            if (maleToken && potionId) {
              navigate(`/characterBuilding/${campaign_id}`);
            } else if (!maleToken && potionId) {
              toast.error("Please select Group A");
            } else if (maleToken && !potionId) {
              toast.error("Please select a Potion");
            } else {
              toast.error("Please select all required tokens.");
            }
          } else if (campaignType === "3") {
            if (potionId) {
              navigate(`/characterBuilding/${campaign_id}`);
            } else {
              toast.error("Please select a potion.");
            }
          } else {
            toast.error("Invalid campaign type.");
          }
        }
      } else {
        toast.error("Campaign data is not available.");
      }
    } else {
      toast.error("Must select 1 potion");
    }
  };

  return (
    <>
      {firstVideo ? (
        <div className="video-overlay">
          <video src={`${PotionBurn}`} autoPlay loop muted></video>
        </div>
      ) : secondVideo ? (
        <div className="video-overlay">
          <video width="100%" src={`${RewardNFT}`} autoPlay loop muted></video>
        </div>
      ) : nftList.length > 0 ? (
        <RewardNftList data={nftList} backgroundLink={campaign?.background} />
      ) : (
        <Grid item sm={6}>
          <Box
            width="100%"
            borderRadius="8px"
            sx={{
              border: `1px solid ${
                campaign?.buttonColor != null
                  ? campaign?.buttonColor
                  : "#6DF8FE"
              }`,
              mt: 2,
              p: 2,
              mb: isScreenLessThan1725px ? 4 : 6,
              background: "#1b1b1b35",
            }}
          >
            <Typography
              sx={{
                width: "100%",
                fontFamily: "Raleway",
                fontSize: "25px",
                // fontWeight:"bold",
                color: "white",
                textAlign: "center",
              }}
            >
              {campaign?.description}
            </Typography>
          </Box>

          {/* <Box
          justifyContent="center"
          // width="80%"
          borderRadius="8px"
          sx={{
            paddingInline: 2,
            p: 1,
            marginInline: 10,
            border: "1px solid #6DF8FE",
            background: "rgba(70, 70, 70, 0.8);",
            justifyContent: "center",
            // ml: isScreenLessThan1725px ? 7 : 0,
            color: "white",
            zIndex: 99,
            position: "relative",
            mt: isScreenLessThan1725px ? 0 : 4,
          }}
        >
          {/* <img src={`${Header2}`} alt="img" width="40%" /> {" :"} */}
          {/* </Box>  */}

          <Box
            sx={{
              display: "flex",
              justifyContent: isScreenLessThan1725px ? "center" : "flex-start",
              mt: isScreenLessThan1725px ? 2 : 2,
              ml:
                campaign.campaignType === "2"
                  ? isScreenLessThan1725px
                    ? "70px"
                    : "15%"
                  : "70px",
            }}
          >
            <Button
              onClick={handleButtonClick}
              size={isScreenLessThan1725px ? "small" : "large"}
              sx={{
                opacity: 1,
                color: "white",
                backgroundColor: "#1b1b1b35", //here I am putting background
                mr: isScreenLessThan1725px ? 8 : 0,
                p: isScreenLessThan1725px ? 1 : 3,
                paddingInline: isScreenLessThan1725px ? 2.5 : 8,
                border: `1px solid ${
                  campaign?.buttonColor != null
                    ? campaign?.buttonColor
                    : "#6DF8FE"
                }`,
                "&:hover": {
                  background:
                    campaign?.buttonColor != null
                      ? campaign?.buttonColor
                      : "rgba(109, 228, 264, 0.9)",
                  color: "white",
                },
                fontSize: isScreenLessThan1725px ? 18 : 28, //here font Size will be putted
                // fontFamily:{}   //here font family will be putted
                fontWeight: 600,
                ml: isScreenLessThan1725px ? "1%" : "38.5%",
              }}
            >
              <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
                {currentRouteName.includes("rewardbag") ? "Open" : "Create"}
              </Typography>
            </Button>
          </Box>
          <ToastContainer />
        </Grid>
      )}
      {modalOpen && (
        <Modal open={modalOpen}>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              sx={{ fontFamily: "Jedi", fontWeight: 600 }}
            >
              {
                "Burn request generated. Awaiting xumm wallet approval! (do not refresh page)"
              }
            </Typography>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default HeaderAndBreedButton;
