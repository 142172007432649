import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import { Base_Url_Sync, Base_Url } from "../../api/api";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/Snackbar/SnackbarReducder";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: 800,
  bgcolor: "rgba(109, 220, 254,1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",

  boxShadow: 24,
  p: 4,
};

function AdminSyncNftsComp() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  const getCount = async () => {
    setLoading(true);
    const response = await axios({
      method: "get",
      url: Base_Url_Sync + "/api/count",
    });
    //console.log(response);
    if (response?.data?.status) {
      setData({
        db_nfts: response?.data?.db_nfts,
        wallet_nfts: response?.data?.wallet_nfts,
      });
    }
    setLoading(false);
  };

  const SyncNfts = async () => {
    setOpenModal(true);
    const response = await axios({
      method: "get",
      // url: Base_Url_Sync + "/api/sync",
      url: Base_Url + "/api/sync-nfts",
    });

    if (response?.status) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: `Data synced successfully `,
        })
      );
      getCount();
      setOpenModal(false);
    } else {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    getCount();
  }, []);

  return (
    <Container sx={{ borderRadius: 4, mt: 2 }}>
      <Paper elevation={4} sx={{ borderRadius: 4 }}>
        <Box
          padding={3}
          // borderRadius={5}
          sx={{
            borderRadius: 4,
            height: "60vh",
            backgroundColor: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">Sync NFTs</Typography>

            <Box>
              <Button
                variant="contained"
                size={"small"}
                sx={{
                  background: "primary",
                  color: "white",

                  "&:hover": {
                    backgroundColor: "#0080FF",
                    color: "f1f1f1",
                    "& .MuiSvgIcon-root": {
                      color: "black", // Change the icon color on hover
                    },
                  },
                  "&:active": {
                    backgroundColor: "#1254e3",
                    color: "f1f1f1",
                    "& .MuiSvgIcon-root": {
                      color: "black", // Change the icon color on click
                    },
                  },
                }}
                onClick={() => {
                  SyncNfts();
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Jedi",
                    display: "flex",
                    direction: "row",
                  }}
                >
                  Sync Nfts
                </Typography>
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              height: "50vh",
            }}
          >
            {loading ? (
              <CircularProgress
                size={70}
                sx={{ color: "linear-gradient(to right, #000428, #004e92)" }}
              />
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h4" mb={2}>
                    Available NFTs
                  </Typography>
                  <Typography
                    sx={{
                      textShadow: "1px 1px 0px grey",
                      border: "2px solid #0080FF",
                      borderRadius: "50%",
                      padding: 2,
                      width: "80px",
                      height: "80px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    color={"#0080FF"}
                    variant="h3"
                  >
                    {data?.db_nfts}
                  </Typography>
                </Box>
              </>
            )}
          </Box>

          <Modal open={modalOpen}>
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h2"
                sx={{
                  fontFamily: "Jedi",
                  fontWeight: 600,
                }}
              >
                {
                  "NFT Synchronization in Progress - Please Wait!  (do not refresh page)"
                }
              </Typography>
              <CircularProgress size={25} sx={{ color: "white" }} />
            </Box>
          </Modal>
        </Box>
      </Paper>
    </Container>
  );
}

export default AdminSyncNftsComp;
