import React from "react";
import AdminSyncNftsComp from "../../Components/Admin-Scenes/AdminSyncNftsComp/AdminSyncNftsComp";
import AdminPanel from "../Admin/AdminPanel";

function SyncNfts() {
  return (
    <AdminPanel>
      <AdminSyncNftsComp />
    </AdminPanel>
  );
}

export default SyncNfts;
