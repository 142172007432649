import { Box, Container } from "@mui/system";
import React, { useEffect, useState, ChangeEvent } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  Button,
  Select,
  CircularProgress,
  MenuItem,
  Paper,
} from "@mui/material";
import { ErrorMessage, Field, FieldArray, FieldProps, Formik } from "formik";
import * as Yup from "yup";
import { axiosRequestLocal } from "../../../api/api";
import DatePicker from "react-datepicker";
import { addMonths } from "date-fns";
import { CampaignType } from "../../../../types/campaignTypes";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  MuiColorInput,
  MuiColorInputValue,
  MuiColorInputColors,
  MuiColorInputFormat,
} from "mui-color-input";
import axios from "axios";
import { Base_Url } from "../../../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import type { ToastOptions } from "react-toastify";
import "./style.css";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";

// import "./MyForm.css";
// import "./MyForm.css";

interface FormDataInterface {
  background: File | null | string;
  logo: File | null | string;
  name: string;
  campaignType: string;
  executionTimes: number;
  videoType: number;
  //title: string;
  status: string;
  description: string;
  createdBy: string;
  launch_Date: Date | null;
  end_Date: Date | null;
  //backgroundColor: string;
  buttonColor: string;
}

const validationSchema = Yup.object().shape({
  videoType: Yup.number()
    .required("Video is required")
    .test("isValidVideo", "Must select 1 option.", (value) => {
      return value !== 0; // Change the condition to show error for value 0
    }),
  background: Yup.mixed(),
  // .required("Background image is required")
  // .test(
  //   "fileType",
  //   "Only image files are allowed",
  //   (value) =>
  //     value && value instanceof File && value.type.startsWith("image/")
  // )
  // .test(
  //   "fileSize",
  //   "File size should be less than or equal to 3MB",
  //   (value) => value && value instanceof File && value.size <= 3 * 1024 * 1024
  // ),
  logo: Yup.mixed(),
  // .required("Logo is required")
  // .test(
  //   "fileType",
  //   "Only image files are allowed",
  //   (value) =>
  //     value && value instanceof File && value.type.startsWith("image/")
  // )
  // .test(
  //   "fileSize",
  //   "File size should be less than or equal to 3MB",
  //   (value) => value && value instanceof File && value.size <= 3 * 1024 * 1024
  // ),
  name: Yup.string()
    // .matches(/^[A-Za-z0-9\s]*$/, "Only alphabets and numbers are allowed")
    .required("Name is required"),
  campaignType: Yup.string().required("Campaign Type is required"),
  executionTimes: Yup.number()
    .positive("Number must be greater than 0")
    .max(5, "Less than or equal to 5")
    .required("Number of NFTs is required"),
  // title: Yup.string()
  //   // .matches(/^[A-Za-z0-9\s]*$/, "Only alphabets and numbers are allowed")
  //   .required("Title is required"),
  description: Yup.string().max(
    120,
    "Description must be at most 200 characters"
  ),
  launch_Date: Yup.date().required("Launch Date is required"),
  end_Date: Yup.date().required("End Date is required"),
  status: Yup.string().required("Status is required"),
  //backgroundColor: Yup.string().required("Background Color is required"),
  buttonColor: Yup.string().required("Button Color is required"),
});

interface campaigns {
  campaign: CampaignType;
  setRefresh: Function;
  refresh: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CampaignUpdateRewardBag = ({
  campaign,
  setRefresh,
  refresh,
}: campaigns) => {
  //console.log("campagin update-->", campaign);
  const location = useLocation();
  const currentRouteName = location.pathname;
  const [bgimg, setImg] = useState<Boolean>(false);
  const [preview, setPreview] = useState("");
  const [logopreview, setLogoPreview] = useState("");
  const [lgimg, setlgimg] = useState<Boolean>(false);
  const [up, setup] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [targetcampaignType, setcampaignType] = useState("");

  const initialValues: FormDataInterface = {
    videoType: campaign?.videoType,
    executionTimes: campaign?.executionTimes,
    background: campaign?.background,
    logo: campaign?.logo,
    name: campaign?.name,
    campaignType: campaign?.campaignType,
    //title: campaign?.title,
    description: campaign?.description,
    status: campaign?.status,
    createdBy: campaign?.createdBy,
    launch_Date: campaign?.launch_Date ? new Date(campaign.launch_Date) : null,
    end_Date: campaign?.end_Date ? new Date(campaign.end_Date) : null,
    //backgroundColor: campaign?.backgroundColor,
    buttonColor: campaign?.buttonColor,
  };

  const handleFormSubmit = async (values: FormDataInterface) => {
    //console.log({ values });
    if (values) {
      //console.log("values------>", values?.background);
      const formDatas = new FormData();
      formDatas.append("name", values?.name);

      //formDatas.append("title", values?.title);

      formDatas.append("description", values?.description);
      formDatas.append("executionTimes", values?.executionTimes.toString());
      formDatas.append("videoType", values?.videoType.toString());

      formDatas.append("status", values?.status);

      formDatas.append("createdBy", values?.createdBy);
      formDatas.append("campaignType", values?.campaignType);

      formDatas.append("launch_Date", values?.launch_Date?.toISOString() || "");
      formDatas.append("end_Date", values?.end_Date?.toISOString() || "");

      //formDatas.append("backgroundColor", values?.backgroundColor);

      formDatas.append("buttonColor", values?.buttonColor);
      // Handle the case where background and logo are File objects or null
      if (values?.background) {
        formDatas.append("background", values?.background);
      }
      if (values?.logo) {
        formDatas.append("logo", values?.logo);
      }
      setLoading(true);

      //console.log(formDatas);
      try {
        if (campaign) {
          const url =
            Base_Url +
            `/api/admin/rewardbagcampaign/update/${campaign.campaign_id}`;
          //console.log(formDatas, "for data");
          const update: any = await axiosRequestLocal("put", url, formDatas);
          //console.log("form data", formDatas);
          //console.log(update, "update");
          toast.success(`Campaign Updated Successfully`, { autoClose: 3000 });
          setLoading(false);
          setRefresh(!refresh);
        }
      } catch (error: any) {
        setLoading(false);

        console.error("An error occurred:", error);
        toast.error(error.response.data.message);
      }
    } else {
      //console.log("else is called");
    }
  };

  // words
  const totalWords = 120;
  const [wordCount, setWordCount] = useState(0);
  // status
  const dummyData = [
    { id: 1, name: "Active" },
    { id: 2, name: "InActive" },
    // Add more dummy data options as needed
  ];
  // get font size
  const fontSizeData = [
    { id: 1, name: "2px" },
    { id: 2, name: "4px" },
    { id: 3, name: "6px" },
    { id: 4, name: "8px" },
    { id: 5, name: "10px" },
    { id: 6, name: "12px" },
    // Add more dummy data options as needed
  ];
  function addDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const campaignTypeData = [
    { id: 1, type: "1-(2 Collections and Potions)" },
    { id: 2, type: "2-(1 Collections and Potions)" },
    { id: 3, type: "3-(Only Potions)" },
  ];
  const [options, setFontFamily] = useState<string[]>(
    dummyData.map((item) => item.name)
  );

  // get Font Family from backend
  const [fonts, setFonts] = useState<string[]>([]);
  const [selectedFont, setSelectedFont] = useState<string>("font family");

  const getFontFamily = async () => {
    //console.log("Working to get Fonts family");

    try {
      const response: any = await axiosRequestLocal(
        "get",
        Base_Url + `/fonts`,
        {},
        {}
      );
      if (response.data) {
        setFonts(response.data);
      }

      //console.log(response);
    } catch (error) {
      //console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getFontFamily();
  }, []);
  const checkValues = (val: any, err: any) => {
    //console.log("what are vals", val);
    //console.log("what are errors", err);
  };

  const VideoData = [
    { id: 1, name: "Potion Burn" },
    { id: 2, name: "Open Pack" },
    // Add more dummy data options as needed
  ];
  return (
    <Container sx={{ borderRadius: 4, mt: 2 }}>
      <Paper elevation={4} sx={{ borderRadius: 4 }}>
        <Box
          padding={3}
          // borderRadius={5}
          sx={{
            borderRadius: 4,
            backgroundColor: "white",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            {campaign.name}{" "}
          </Typography>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{ mt: "2px" }}>
                  {/* ------------- Background------------- */}
                  {/* <Button
                    onClick={() => {
                      checkValues(values, errors);
                    }}
                  ></Button> */}
                  <Grid item xs={6}>
                    <FormControl
                      // fullWidth
                      error={!!touched.background && !!errors.background}
                    >
                      <Box sx={{ position: "relative" }}>
                        <div
                          style={{
                            marginTop: "12px",
                            height: "200px",
                            marginBottom: "5px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "#555555",
                              fontSize: "14px",
                              marginBottom: "20px",
                            }}
                          >
                            Background Preview
                          </Typography>
                          <div>
                            <img
                              src={
                                preview
                                  ? preview
                                  : currentRouteName.includes("rewardbag")
                                  ? `${Base_Url}/rewardBagBackground/${values?.background}`
                                  : `${Base_Url}/${values?.background}`
                              }
                              alt="Preview"
                              style={{
                                width: "100%",
                                height: "150px",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </div>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "#555555",
                            fontSize: 12,
                          }}
                        >
                          Update Background
                        </Typography>
                        <Input
                          style={{ width: 250 }}
                          type="file"
                          className="custom-file-input-campaign"
                          inputProps={{
                            accept: "image/*",
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(event);
                              const file =
                                event.currentTarget.files &&
                                event.currentTarget.files[0];
                              if (file) {
                                setFieldValue("background", file);
                                setImg(!bgimg);
                                setPreview(URL.createObjectURL(file));
                              }
                            },
                          }}
                          name="background"
                          id="background-input"
                        />

                        {touched.background && !!errors.background && (
                          <FormHelperText>{errors.background}</FormHelperText>
                        )}
                      </Box>
                    </FormControl>
                  </Grid>
                  {/* ------------- Logo------------- */}
                  <Grid item xs={6}>
                    <FormControl error={!!touched.logo && !!errors.logo}>
                      <Box
                        sx={{ position: "relative", marginBottom: "1yy0px" }}
                      >
                        <div
                          style={{
                            marginTop: "12px",
                            height: "200px",
                            marginBottom: "5px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "#555555",
                              fontSize: "14px",
                              marginBottom: "20px",
                            }}
                          >
                            Logo Preview
                          </Typography>
                          <div>
                            <img
                              src={
                                logopreview
                                  ? logopreview
                                  : currentRouteName.includes("rewardbag")
                                  ? `${Base_Url}/rewardBagLogo/${values?.logo}`
                                  : `${Base_Url}/${values?.logo}`
                              }
                              alt="Preview"
                              style={{
                                width: "100%",
                                height: "150px",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </div>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: "#555555",
                            fontSize: 12,
                          }}
                        >
                          Update Logo
                        </Typography>
                        <Input
                          type="file"
                          style={{ width: 250 }}
                          className="custom-file-input-campaign"
                          inputProps={{
                            accept: "image/*",
                            onChange: (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChange(event);
                              const file =
                                event.currentTarget.files &&
                                event.currentTarget.files[0];
                              if (file) {
                                setFieldValue("logo", file);
                                setImg(!bgimg);
                                setLogoPreview(URL.createObjectURL(file));
                              }
                            },
                          }}
                          name="logo"
                          id="logo-input"
                        />
                        {touched.logo && !!errors.logo && (
                          <FormHelperText>{errors.logo}</FormHelperText>
                        )}
                      </Box>
                    </FormControl>
                  </Grid>
                  {/* ------------- Name------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Name
                    </Typography>
                    <div>
                      <Field name="name">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            size="small"
                            placeholder="Name"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------- Campaign Type ------------- */}
                  {/* <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Select Campaign Type
                    </Typography>
                    <FormControl style={{ minWidth: 120 }} size="small">
                      <Select
                        // size="small"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        displayEmpty
                        placeholder="Campaign Type"
                        name="campaignType"
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        // renderValue={(selected) => {
                        //   if (selected.length === 0) {
                        //     return <em>Campaign Type</em>;
                        //   }

                        //   return selected;
                        // }}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <Typography>Campaign Type</Typography>;
                          }
                          const selectedCampaign = campaignTypeData.find(
                            (campaignTypeData) =>
                              campaignTypeData?.id === Number(selected)
                          );
                          return selectedCampaign ? (
                            selectedCampaign.type
                          ) : (
                            <em>Campaigns Type</em>
                          );
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                        value={values?.campaignType}
                        sx={{ width: "250px" }}
                        onChange={(e) => {
                          handleChange(e);
                          const selectedType = e.target.value as string;
                          setcampaignType(selectedType);
                        }}
                        error={
                          touched.campaignType && Boolean(errors.campaignType)
                        }
                      >
                        {campaignTypeData?.map((option: any) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.type}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.campaignType && errors.campaignType && (
                        <FormHelperText error>
                          {errors.campaignType}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid> */}
                  {/* ------------- Title------------- */}
                  {/* <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Title
                    </Typography>
                    <div>
                      <Field name="title">
                        {({ field, meta }: FieldProps<string>) => (
                          <TextField
                            id="outlined-size-small"
                            type="text"
                            placeholder="Title"
                            size="small"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid> */}
                  {/* ------------- Status ------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Select Status
                    </Typography>
                    <FormControl style={{ minWidth: 120 }} size="small">
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        displayEmpty
                        name="status"
                        sx={{ width: "250px" }}
                        value={values?.status}
                        onChange={handleChange}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        renderValue={(selected) => {
                          if (selected?.length === 0) {
                            return <em>Status</em>;
                          }

                          return selected;
                        }}
                        error={touched.status && Boolean(errors.status)}
                      >
                        {dummyData.map((option) => (
                          <MenuItem key={option.id} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.status && errors.status && (
                        <FormHelperText error>{errors.status}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* ------------- Description ------------- */}
                  {/* <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Select Font Family
                    </Typography>
                    <FormControl style={{ minWidth: 120 }} size="small">
                      <Select
                        sx={{ width: "250px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="fontFamily"
                        displayEmpty
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        value={values?.fontFamily}
                        onChange={handleChange}
                        renderValue={(selected) => {
                          if (selected?.length === 0) {
                            return <em>Font Family</em>;
                          }

                          return selected;
                        }}
                        error={
                          touched?.fontFamily && Boolean(errors?.fontFamily)
                        }
                      >
                        {fonts?.map((font) => (
                          <MenuItem key={font} value={font}>
                            {font}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched?.fontFamily && errors?.fontFamily && (
                        <FormHelperText error>
                          {errors.fontFamily}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Select Font Size
                    </Typography>
                    <FormControl style={{ minWidth: 120 }} size="small">
                      <Select
                        sx={{ width: "250px" }}
                        displayEmpty
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="fontSize"
                        value={values?.fontSize}
                        onChange={handleChange}
                        renderValue={(selected) => {
                          if (selected?.length === 0) {
                            return <em>Font Size</em>;
                          }

                          return selected;
                        }}
                        error={touched?.fontSize && Boolean(errors?.fontSize)}
                      >
                        {fontSizeData.map((fontSize) => (
                          <MenuItem key={fontSize.id} value={fontSize.name}>
                            {fontSize.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched?.fontSize && errors?.fontSize && (
                        <FormHelperText error>{errors.fontSize}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid> */}
                  {/* <----------------url------------------> */}
                  {/* <Grid item lg={6} sm={12} xs={12}>
                  <Typography sx={{ fontWeight: "bold", color: "#555555" }}>
                    Url
                  </Typography>
                  <div>
                    <Field name="url">
                      {({ field, meta }: FieldProps<string>) => (
                        <TextField
                          id="outlined-size-small"
                          type="text"
                          placeholder="url"
                          size="small"
                          sx={{ marginTop: "2px", width: "250px" }}
                          {...field}
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </div>
                </Grid> */}
                  {/*---------------- Typogrpahy --------------- */}
                  {/*---------------- Theme --------------- */}
                  {/* <Grid xs={4} item>
                    <FormControl>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#555555",
                          fontSize: 12,
                        }}
                      >
                        Background Color
                      </Typography>
                      <MuiColorInput
                        size="small"
                        sx={{ width: "250px" }}
                        value={values?.backgroundColor || "rgb(0, 0, 0)"}
                        onChange={(color) =>
                          setFieldValue("backgroundColor", color)
                        }
                        format={"rgb"}
                      />
                      {touched?.backgroundColor && errors?.backgroundColor && (
                        <Typography variant="caption" color="error">
                          {errors.backgroundColor}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid> */}
                  <Grid lg={4} sm={12} xs={12} item>
                    <FormControl>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "#555555",
                          fontSize: 12,
                        }}
                      >
                        Button Color
                      </Typography>
                      <MuiColorInput
                        size="small"
                        value={values?.buttonColor || "rgb(0, 0, 0)"}
                        onChange={(color) =>
                          setFieldValue("buttonColor", color)
                        }
                        format={"rgb"}
                      />
                      {touched.buttonColor && errors?.buttonColor && (
                        <Typography variant="caption" color="error">
                          {errors.buttonColor}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>{" "}
                  {/* ------------- Lanch Date------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Launch Date
                    </Typography>
                    <Field name="launch_Date" style={{ width: "250px" }}>
                      {({ field, form }: { field: any; form: any }) => (
                        <div className="custom-datepicker-wrapper">
                          <DatePicker
                            showIcon
                            selected={field.value || new Date()}
                            onChange={(date: Date) =>
                              form.setFieldValue("launch_Date", date)
                            }
                            minDate={new Date()}
                            maxDate={addMonths(new Date(), 5)}
                            className="custom-datepicker"
                          />
                          <ErrorMessage
                            name="launch_Date"
                            component="div"
                            className="error-message"
                          />
                        </div>
                      )}
                    </Field>
                  </Grid>
                  {/* ------------- End Date------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      End Date
                    </Typography>

                    <Field name="end_Date" sx={{ width: "250px" }}>
                      {({ field, form }: { field: any; form: any }) => (
                        <div
                          className="custom-datepicker-wrapper"
                          style={{ display: "table-cell" }}
                        >
                          <DatePicker
                            showIcon
                            selected={field.value || null}
                            dateFormat="MM/dd/yyyy"
                            value={
                              (field.value &&
                                format(field.value, "MM/dd/yyyy")) ||
                              "MM/DD/YYYY"
                            }
                            onChange={(date: Date) =>
                              form.setFieldValue("end_Date", date)
                            }
                            minDate={
                              form.values.launch_Date
                                ? addDays(form.values.launch_Date, 1)
                                : null
                            }
                            // minDate={new Date()}
                            maxDate={addMonths(new Date(), 5)}
                            className="custom-datepicker"
                          />
                          <div>
                            <ErrorMessage
                              name="end_Date"
                              component="div"
                              className="error-message-date"
                            />
                          </div>
                        </div>
                      )}
                    </Field>
                  </Grid>
                  {/* ------------- Execution Times ------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Number of NFTs
                    </Typography>
                    <div>
                      <Field name="executionTimes">
                        {({ field, meta }: FieldProps<number>) => (
                          <TextField
                            id="outlined-size-small"
                            type="number"
                            size="small"
                            placeholder="Number of NFTs"
                            sx={{ marginTop: "2px", width: "250px" }}
                            {...field}
                            error={meta.touched && !!meta.error}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </div>
                  </Grid>
                  {/* ------------- Video Type ------------- */}
                  <Grid item lg={4} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Video
                    </Typography>
                    <FormControl style={{ minWidth: 120 }} size="small">
                      <Select
                        // size="small"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        displayEmpty
                        placeholder="Campaign Type"
                        name="videoType"
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <Typography>Select Video</Typography>;
                          }
                          const selectedCampaign = VideoData.find(
                            (campaignTypeData) =>
                              campaignTypeData?.id === Number(selected)
                          );
                          return selectedCampaign ? (
                            selectedCampaign.name
                          ) : (
                            <em>Select Video</em>
                          );
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                        value={values?.videoType}
                        sx={{ width: "250px" }}
                        onChange={handleChange}
                        error={touched.videoType && Boolean(errors.videoType)}
                      >
                        {VideoData?.map((option: any) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.videoType && errors.videoType && (
                        <FormHelperText error>
                          {errors.videoType}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {/* ----------------Description----------------- */}
                  <Grid item lg={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#555555",
                        fontSize: 12,
                      }}
                    >
                      Description
                    </Typography>
                    <div style={{ position: "relative" }}>
                      <TextField
                        size="small"
                        sx={{ marginTop: "2px", width: "91%" }}
                        type="text"
                        multiline
                        placeholder="Description"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          const characterCount = event.target.value.length;
                          setWordCount(characterCount);
                          setFieldValue("description", event.target.value);
                        }}
                        value={values?.description}
                        name="description"
                        inputProps={{
                          style: {
                            height: "90px",
                            width: "100%",
                            paddingRight: "70px", // Add space for the word count
                          },
                          maxLength: 120,
                        }}
                        error={!!touched.description && !!errors.description}
                        helperText={touched.description && !!errors.description}
                      />
                      <Typography
                        sx={{
                          position: "absolute",
                          bottom: "8px",
                          right: "109px",
                          color: "#555555",
                          fontSize: "16px",
                        }}
                      >
                        {wordCount} / {totalWords}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="start" mt="40px">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#454545",
                      width: "100px",
                      height: "40px",
                    }}
                  >
                    {loading === false ? (
                      "Update"
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        <ToastContainer />
      </Paper>
    </Container>
  );
};

export default CampaignUpdateRewardBag;
