import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Box,
  Paper,
  Card,
  Grid,
  Button,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import BackgroundImage from "../../assets/Background/backgroundImage.png";
import Logo from "../../../assets/BreedingAssets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { axiosRequestLocal } from "../../api/api";
import Cards from "./Cards";
import { Base_Url } from "../../api/api";
import AdminBackground from "../../../assets/Background/adminbackground.jpg";
import LogoutComp from "../../Logout/Logout";
import styled, { keyframes } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Pagination, Navigation, Scrollbar } from "swiper";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// import SwiperCore, {
//   Navigation,
//   Pagination,
//   Autoplay,
//   Virtual,
// } from "swiper/core";
import "swiper/css";
import "swiper/swiper-bundle.min.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
import { CampaignType } from "../../../types/campaignTypes";
import { debounce } from "lodash";
import { setCampaigns } from "../../../redux/CampaignsReducer/CampaignsReducer";
import { setPotionId } from "../../../redux/PotionReducer/PotionReducer";
import {
  setFemale,
  setMale,
  setUri,
} from "../../../redux/GenderReducer/GenderReducer";

const Campaign = () => {
  const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

  const AnimatedTypography = styled(Typography)`
    && {
      font-family: "Poppins", sans-serif;
      color: white;
      padding: 0;
      margin: 0;
      // animation: ${fadeInAnimation} 1s ease-in-out;

      &:hover {
        color: yellow;
      }
    }
  `;

  // ---------------------------UseStates----------------------------
  // const seletor = useSelector();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRouteName = location.pathname;

  const swiperRef = useRef<any>();
  const bigScreenCheck = useMediaQuery("(max-width:1725px)");
  const [data, setdata] = useState<CampaignType[]>([]);
  const [bg, setbg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [allData, setAllData] = useState([]);
  let campaignLength = data?.length;
  const mobileViewCheck = useMediaQuery("(min-width:1023px)");
  const navigate = useNavigate();
  const accountAddress = localStorage.getItem("accnum");
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  // -------------------------Functions----------------------------
  const getData = async () => {
    try {
      const res = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/campaign`
      );
      //console.log(res);
      setAllData(res.data.campaigns);
      setdata(res.data.campaigns);

      // const filteredData = res.data.campaigns.filter((item: CampaignType) =>
      //   item.title.toLowerCase().includes(searchQuery.toLowerCase())
      // );

      setIsLoading(false);
    } catch (error) {
      //console.log(error);
      setIsLoading(false);
    }
  };
  const getDataRewardBag = async () => {
    try {
      const res = await axiosRequestLocal(
        "get",
        Base_Url + `/api/admin/rewardbagcampaign`
      );
      //console.log(res);
      setAllData(res.data.campaigns);
      setdata(res.data.campaigns);

      // const filteredData = res.data.campaigns.filter((item: CampaignType) =>
      //   item.title.toLowerCase().includes(searchQuery.toLowerCase())
      // );

      setIsLoading(false);
    } catch (error) {
      //console.log(error);
      setIsLoading(false);
    }
  };

  const debouncedSearch = debounce((query: string) => {
    handleSearch(query);
  }, 300);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const query = event.target.value;
    setSearchQuery(query);
    setIsLoading(true);
    debouncedSearch(query);
  };

  const handleSearch = (query: string) => {
    if (!query) {
      setdata(allData);
      return;
    }
    // Filter the data based on the searchQuery and update the filteredData state
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    // Update the data state with the filtered data
    setdata(filteredData);
  };
  const goToAdminPanel = () => {
    if (currentRouteName.includes("rewardbag"))
      navigate("/admin-panel/rewardbag");
    else navigate("/admin-panel");
  };

  const checkAdmin = async (accountAddress: string): Promise<void> => {
    try {
      //console.log("LOGGED --->", accountAddress);
      if (accountAddress && accountAddress.length) {
        let URL = `${Base_Url}/api/admin/check/${accountAddress}`;
        //console.log(URL);
        const response: any = await axiosRequestLocal("get", URL);
        //console.log("Response of IsAdmin", response.data.status);
        setIsAdmin(response.data.status);
      }
    } catch (error) {
      //console.log("Response of IsAdmin", error);
      //console.log(error);
    }
  };

  function renderCampaigns(
    data: any,
    isLoading: any,
    campaignLength: any,
    mobileViewCheck: any
  ) {
    const currentDate = new Date();
    const endDate = new Date(data.end_Date);

    if (isLoading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "100px",
          }}
        >
          <CircularProgress size={70} sx={{ color: "white" }} />
        </Box>
      );
    } else if (data?.length > 0) {
      return (
        <Swiper
          lazy={true}
          navigation={mobileViewCheck ? true : false}
          pagination
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          slidesPerView={
            mobileViewCheck
              ? campaignLength === 1
                ? 1
                : campaignLength === 2
                ? 2
                : 3
              : 1
          }
          style={{ height: mobileViewCheck ? "50%" : "42%" }}
        >
          <Box sx={{ mx: 40, mb: 20 }}>
            {data?.map((d: any, i: number) => {
              const currentDate = new Date();
              const endDate = new Date(d.end_Date);
              const isActive = d.status === "Active";

              // Use a ternary operator to conditionally render the Cards component
              return endDate >= currentDate && isActive ? (
                <SwiperSlide key={`slide-${i}`} style={{ listStyle: "none" }}>
                  <Cards data={d} />
                </SwiperSlide>
              ) : null; // or a message indicating why it's not displayed
            })}
          </Box>
        </Swiper>
      );
    } else {
      return (
        <SwiperSlide>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              // margin: "100px",
              width: "100%",
              color: "white",
            }}
          >
            <Typography
              variant="h5"
              sx={{ marginBottom: bigScreenCheck ? "500px" : "500px" }}
            >
              No Campaign Available at the Moment !
            </Typography>
          </Box>
        </SwiperSlide>
      );
    }
  }

  // ----------------------------useEffects--------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = "hidden";
    if (accountAddress) {
      checkAdmin(accountAddress);
    }
    dispatch(setCampaigns(null));
    dispatch(setPotionId(""));
    dispatch(setMale(""));
    dispatch(setFemale(""));
    dispatch(setUri(""));
    if (currentRouteName.includes("rewardbag")) getDataRewardBag();
    else getData();
  }, []);

  //console.log("PORT-->", process.env.PORT);

  return (
    <>
      <div
        style={{
          background: "linear-gradient(to right, #000428, #af22c8)",
          width: "100%",
          height: "100vh",
        }}
      >
        {mobileViewCheck ? (
          <Box sx={{ pt: 2, display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Button
                onClick={() => {
                  navigate("/select-campaign");
                }}
              >
                <ArrowBackIcon sx={{ color: "white" }} />
              </Button>
            </Box>
            <Box sx={{ pl: 12 }}>
              <img src={Logo} alt="Lux Labs" />
            </Box>

            <Box sx={{ pr: 3, mt: 2 }}>
              {isAdmin && (
                <Button
                  variant="contained"
                  onClick={goToAdminPanel}
                  size="small"
                  sx={{
                    color: "white",
                    background: "#1b1b1b35",
                    mb: "10px",
                    border: "1px solid #6DF8FE",
                    "&:hover": {
                      background: "rgba(109, 258, 264, 0.6)",
                      color: "black",
                    },
                  }}
                >
                  Go to Admin Panel
                </Button>
              )}
              <Box>
                <LogoutComp />
              </Box>
            </Box>
          </Box>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Box>
                {/* <Button
                  onClick={() => {
                    navigate("/select-campaign");
                  }}
                >
                  <ArrowBackIcon sx={{ color: "white" }} />
                </Button> */}
              </Box>
              <Box
                sx={{
                  mt: 2,
                  justifyContent: "space-between",
                  display: "flex",
                  px: 4,
                }}
              >
                <Box>
                  <img
                    onClick={() => {
                      navigate("/select-campaign");
                    }}
                    src={Logo}
                    alt="img"
                    width="70%"
                  />
                </Box>
                <Box>
                  <Box sx={{ mt: "5px", mr: "10px" }}>
                    <LogoutComp />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}

        {currentRouteName.includes("rewardbag") ? (
          <Box sx={{ textAlign: "center", mt: 5 }}>
            {mobileViewCheck ? (
              <AnimatedTypography
                variant="h4"
                sx={{ fontFamily: "jedi", color: "#ffff" }}
              >
                Welcome to Pack Openingss
              </AnimatedTypography>
            ) : (
              <AnimatedTypography
                variant="h5"
                sx={{ fontFamily: "jedi", color: "#ffff" }}
              >
                Welcome to Pack Openingss
              </AnimatedTypography>
            )}
          </Box>
        ) : (
          <Box display={"flex"} justifyContent={"center"} marginTop={5}>
            {mobileViewCheck ? (
              <Typography
                variant="h4"
                sx={{ fontFamily: "jedi", color: "#ffff" }}
              >
                Welcome to Creation Lab
              </Typography>
            ) : (
              <Typography
                variant="h5"
                sx={{ fontFamily: "jedi", color: "#ffff" }}
              >
                Welcome to Creation Lab
              </Typography>
            )}
          </Box>
        )}
        <Box display={"flex"} justifyContent={"center"} marginTop={2}>
          <TextField
            size="small"
            placeholder="Search Campaigns"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => {
              const query = e.target.value;
              setSearchQuery(query);
              handleSearch(query); // Call the search function on every input change
            }}
            InputProps={{
              startAdornment: <SearchIcon style={{ color: "white" }} />,
              endAdornment: searchQuery && (
                <IconButton
                  onClick={() => {
                    setSearchQuery("");
                    setdata(allData);
                  }}
                >
                  <ClearIcon style={{ color: "white" }} />
                </IconButton>
              ),
            }}
            sx={{
              ".css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input": {
                color: "white",
              },
              ".MuiInputBase-input": {
                color: "white",
              },
              color: "white",
              borderRadius: "6px",
              weight: "40px",
              marginBottom: "20px",
              background: "rgba( 192, 200, 225, 0.4 );",
              backdropFilter: "blur(10px)",
              WebkitBackdropFilter: "blur(2px)",
              border: "1px solid rgba(255, 255, 255, 0.18)",
            }}
          />
        </Box>
        {renderCampaigns(data, isLoading, campaignLength, mobileViewCheck)}
      </div>
    </>
  );
};

export default Campaign;
