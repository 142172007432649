import axios from "axios";

const ENVIRONMENT: environmentType = "PRODUCTION";

export let API: API_I;
// export let Base_Url = "http://192.168.100.59:3000"; // local link
// export let Base_Url = "http://192.168.100.101:3000"; // local link
// export let Base_Url = "https://xrp.thecbt.live"; // testing environment link
// export let Base_Url = "https://xrp.thecbt.live";   // cherry byte server link 
//  export let Base_Url = "https://xrpapi.thecbt.live";  //Client Server link
//   export let Base_Url = "https://labtwoapi.thecbt.live";  //2nd test Server link
export let Base_Url = "https://rwbagapi.thecbt.live"; // Reward back link

export let EmailSupport ="https://sync.thecbt.live/api/support"

// export let EmailSupport ="http://192.168.100.101:7000/api/support"

// export let API_Socket = "http://192.168.100.59:9000";    // local link
// export let API_Socket = "http://192.168.100.101:9000";    // local link
// export let API_Socket = "https://socket-xrp.thecbt.live";    // testing environement link
// export let API_Socket = "https://socket-xrp.thecbt.live"; // cherry byte server link 
// export let API_Socket = "https://xrpsocketapp.thecbt.live"; //Client Server link 
// export let API_Socket = "https://xrpsocketapp.thecbt.live"; //2nd test Server link
export let API_Socket = "https://rwsocket.thecbt.live"; // Reward back link




// https://labtwosocket.thecbt.live
export let Base_Url_Sync = "https://sync.thecbt.live"; // local link

function initApi(ENVIRONMENT_ARG: environmentType) {
  if (ENVIRONMENT_ARG === "DEVELOPMENT") {
    API = {
      node_backend: Base_Url + `/`,
      socket_backend: API_Socket,
      cubApiURL: "api/luxlion/cubs",
      ledger: "https://livenet.xrpl.org",
      nomanPc: "http://192.168.1.10:60000/api/",
    };
  } else {
    API = {
      node_backend: "https://backend.luxlionsnft.com/",
      socket_backend: "https://www.socket.luxlionsnft.com",
      cubApiURL: "api/luxlion/cubs",
      ledger: "https://livenet.xrpl.org",
      nomanPc: "http://192.168.1.10:60000/api/",
    };
  }
}

initApi(ENVIRONMENT);

export const axiosRequest = async (
  method_: methodType,
  url_: string,
  body?: any,
  params_?: any
) => {
  const URL = Base_Url + "/" + url_;

  const response = await axios({
    method: method_,
    url: URL,
    data: body,
    params: params_,
  });
  return response;
};

export const axiosRequestEmail = async (
  method_: methodType,
  url_: string,
  body?: any,
  params_?: any
) => {
  const URL = EmailSupport

  const response = await axios({
    method: method_,
    url: URL,
    data: body,
    params: params_,
  });
  return response;
};


export const axiosRequestNFT = async (
  method_: methodType,
  url_: string,
  body?: any,
  params_?: any
) => {
  const URL = Base_Url + "/" + url_;

  const response = await axios({
    headers: {
      "Content-Type": "application/json",
    },
    method: method_,
    url: URL,
    data: body,
    params: params_,
  });
  return response;
};

export const axiosRequestLocal = async (
  method_: methodType,
  url_: string,
  body?: any,
  params_?: any
) => {
  const URL = url_;

  const response = await axios({
    method: method_,
    url: URL,
    data: body,
    params: params_,
    
  });
  return response;
};

export const axiosRequestAuth = async (
  method_: methodType,
  url_: string,
  body?: any,
  params_?: any,
  timeout_?: number
) => {
  const token = localStorage.getItem("token");
  // const token = localStorage.getItem("accnum");

  const URL = Base_Url + `/` + url_;

  // try {d
    const response = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: method_,
      url: URL,
      data: body,
      params: params_,
      timeout: timeout_
    });
    return response;
  // } catch (error) {
  //   // Handle the error here
  
  //   console.error("An error occurred:", error);
  //   return false
  //   // You can throw the error again, return a specific value, or handle it in any way you need.
  // }
};

export const axiosRequestHeaders = async (
  method_: methodType,
  url_: string,
  body?: any,
  params_?: any
  // headers_?: any
) => {
  const token = localStorage.getItem("token");
  const URL = url_;

  const response = await axios({
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: method_,
    url: URL,
    data: body,
    params: params_,
  });
  return response;
};

interface API_I {
  node_backend: string;
  socket_backend: string;
  cubApiURL: `api/luxlion/cubs`;
  ledger: `https://livenet.xrpl.org` | `https://testnet.xrpl.org/`;
  nomanPc: string;
}

type methodType = "get" | "post" | "patch" | "delete" | "put";

type environmentType = "PRODUCTION" | "DEVELOPMENT";
